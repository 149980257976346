import Cookies from 'universal-cookie';

const cookies = new Cookies();
const {REACT_APP_DOMAIN} = process.env;

const getJWT = () => {
    return cookies.get('jwtToken');

};

export const getConfigObject = () => {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': getJWT(),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
};

export const logOut = () => {
    cookies.remove('userInfos', {path: "/", domain: REACT_APP_DOMAIN});
    cookies.remove('jwtToken', {path: "/", domain: REACT_APP_DOMAIN});
    cookies.remove('organization', {path: "/", domain: REACT_APP_DOMAIN});
    cookies.remove('employeeConnected', {path: "/", domain: REACT_APP_DOMAIN});
    window.open('https://portal' + '.' + REACT_APP_DOMAIN + '/login', "_parent")
};

export const isConnected = () => {
    return cookies.get('jwtToken');
};